<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>
                현재 스페이스는 다른 기기에서 이용 중입니다. <br />
                이 기기에서 스트리밍을 하시면 다른 기기에서 이용이 중단됩니다. <br />
                이 기기에서 스트리밍 하시겠습니까?
            </h3>
            <div class="single-file-btn">
                <p @click="closeModal()">아니오</p>
                <p id="color-text" @click="linkPlayer()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import axios from "axios";
import Hls from "hls.js";
export default defineComponent({
  name: 'SpaceConnection2',
  props: {
    spaceId: String,
    spaceNm: String
  },
  data () {
    return {
      config: {
        debug: false,
        startPosition: -1,
        enableWorker: true,
        lowLatencyMode: true,
        maxBufferLength: 30,
        maxMaxBufferLength: 600,
        backBufferLength: 0,
        manifestLoadingTimeOut: 10000,
        manifestLoadingMaxRetry: 3,
        manifestLoadingRetryDelay: 1000,
        manifestLoadingMaxRetryTimeout: 64000,
        startLevel: undefined,
        fragLoadingTimeOut: 30000,
        fragLoadingMaxRetry: 10,
        fragLoadingRetryDelay: 1000,
        fragLoadingMaxRetryTimeout: 100000,
        nudgeOffset: 0,
        playlistLoadPolicy: {
          default: {
            maxTimeToFirstByteMs: 10000,
            maxLoadTimeMs: 20000,
            timeoutRetry: {
              maxNumRetry: 2,
              retryDelayMs: 0,
              maxRetryDelayMs: 0
            },
            errorRetry: {
              maxNumRetry: 10,
              retryDelayMs: 1000,
              maxRetryDelayMs: 2000
            }

          }
        }
      }
    };
  },
  methods: {
    closeModal () {
      var musicInfo = { nextFlag: '', auto: false };
      this.$store.commit("setSendMusicInfo", musicInfo);
      this.$emit('closeModal');
    },
    getCarPurpose () {
      return document.querySelector('#car_purpose_video');
    },
    linkPlayer () {
      var userId = VueCookies.get("userId");
      var spaceId = this.spaceId ? this.spaceId : VueCookies.get("spaceId");
      var sessionId = localStorage.getItem("sessionId");
      var recentSpaceId = localStorage.getItem("recentSpaceId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/spaceSession/spaceConnection", { userId, spaceId, sessionId, recentSpaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            localStorage.setItem("sessionId", result.sessionId);
            if (result.resultCd === 'D0004') {
              localStorage.setItem("recentSpaceId", spaceId);

              VueCookies.set("spaceId", spaceId);
              this.$store.commit("setSpaceId", spaceId);
              this.$store.commit("setSpaceNm", this.spaceNm);
              this.$store.commit("setPlay", true);
              this.$store.commit("setSpaceConnection", false);
              var musicInfo = { nextFlag: '', auto: false };
              this.$store.commit("setSendMusicInfo", musicInfo);
              this.$emit('closeModal2');
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    playerMusic (url) {
      console.log('playMusic');
      let video = this.getWsVideo();
      let hls;
      const tg = url;
      if (Hls.isSupported()) {
        if (this.$store.hls !== undefined) this.$store.hls.destroy(); // removed all listeners
        this.$store.hls = new Hls(this.config);
        hls = this.$store.hls;
        hls.loadSource(tg);

        hls.on(Hls.Events.ERROR, (event, data) => { });
        hls.attachMedia(video);
        this.$store.commit("setPlay", true);
        video.muted = false;
      } else if (video != null && video.canPlayType("application/vnd.apple.mpegurl")) {
        // iOS인 경우로 따로 대응해주어야 한다.
        let v = this.getWsVideo();
        if (v.querySelector("source") === null) {
          const pa = video.parentElement;
          // programmatic 하게 플레이어를 생성해야 한다.
          pa.removeChild(v);
          v = document.createElement("video");
          v.id = "video";
          v.playsInline = true;
          v.autoplay = true;
          v.muted = false;
          v.volume = this.$store.state.volume;

          const s = document.createElement("source");
          s.type = "application/vnd.apple.mpegurl";

          v.appendChild(s);
          pa.appendChild(v);
          video = v;
        } else {
          video.removeChild(video.querySelector('source'));
          const s = document.createElement("source");
          s.type = "application/vnd.apple.mpegurl";

          video.appendChild(s);
        }
        video.querySelector("source").src = tg;
        video.play();
      }
    },
    getWsVideo () {
      return document.getElementById('video');
    }
  }

});
</script>
<style scoped>
.single-file-box h3 {
  line-height: 1.3;
}
</style>
