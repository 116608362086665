<template>
    <div
        :class="isHidden ? 'floating_inactive' : ''"
        @click="linkPlayer()"
        class="floating_btn"
        v-if="currentMusic && spaceInfo && spaceInfo.spacePayAt !== 'N'"
    >
        <div class="floating_img" v-if="isPlay">
            <img v-if="currentMusicImg" :src="currentMusic.albumImg" alt="" style="border-radius: 50%" />
            <img
                v-else-if="channelMusicInfoImg"
                :src="this.$store.state.channelMusicInfo.albumImg"
                alt=""
                style="border-radius: 50%"
            />
            <img
                v-else-if="channelMusicInfoImg && this.$store.state.channelMusicInfo.albumImg.includes('emptyImg')"
                v-once
                :src="this.$store.state.channelMusicInfo.albumImg"
                alt=""
                style="border-radius: 50%"
            />
            <img
                v-else-if="brandImg && $store.state.brandMusicInfo"
                :src="$store.state.brandMusicInfo.albumImg ? $store.state.brandMusicInfo.albumImg : ''"
                alt=""
                style="border-radius: 50%"
            />
            <img v-else src="/media/img/Group 485830.svg" alt="" />
        </div>
        <div class="floating_img2" v-else-if="!isPlay && !currentMusic.albumImg">
            <img src="/media/img/Group 485830.svg" alt="" />
        </div>
        <div class="floating_img2" v-else>
            <img :src="currentMusic.albumImg" v-if="currentMusic.albumImg != null" style="border-radius: 50%" alt="" />
            <img
                :src="this.$store.state.channelMusicInfo.albumImg"
                v-else-if="currentMusic.albumImg == null && this.$store.state.channelMusicInfo != null"
                style="border-radius: 50%"
                alt=""
            />
            <img src="/media/img/Group 485830.svg" v-else alt="" />
        </div>
        <div v-if="isPlay">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import $ from 'jquery';

import { joinRecomChannel } from '@/assets/js/webcast';
import axios from 'axios';
import { mySpaceInfo } from '@/service/api/profileApi';
export default {
  emits: ['getmychannelinfo'],
  name: 'Floating',
  data () {
    return {
      spaceId: VueCookies.get('spaceId'),
      musicInfoInterval: null,
      spaceInfo: null
    };
  },
  props: {
    currentMusic: Object,
    isPlay: Boolean,
    isHidden: {
      type: Boolean,
      default: false
    },
    channelInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    recomList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  created () {
    this.musicInfoInterval = setInterval(() => {
      // 채널 음원, 추천 채널 정보 5초 단위로 가져오기
      if (this.$store.state.channelId !== '') this.getChannelMusicInfo(); // 채널 음원 정보
    }, 1000 * 5);
    this.getMySpaceInfo();
  },

  computed: {
    currentMusicImg () {
      if (this.currentMusic) {
        return (
          this.currentMusic.albumImg != null && !this.$store.state.isBrandMusicPlay && this.$store.state.isPlay === true
        );
      }
      return true;
    },
    channelMusicInfoImg () {
      return (
        this.currentMusic.albumImg == null &&
        this.$store.state.channelMusicInfo != null &&
        !this.$store.state.isBrandMusicPlay &&
        this.$store.state.isPlay === true
      );
    },
    brandImg () {
      return this.$store.state.channelMusicInfo;
    },
    isPlaying () {
      return this.$VideoPlayer.getPaused();
    }
  },

  async mounted () {
    if (this.$store.state.division === 'Building') {
      $('.floating_btn').css({ display: 'none' });
    }
    this.$nextTick(async () => {
      this.$MediaSession.addPrevioustrack(this.channelPreBtn);
      this.$MediaSession.addNexttrack(this.channelNextBtn);

      if (Object.keys(this.$route.params).length > 0) {
        const { params } = this.$route;
        const { isPlay } = params;
        if (isPlay && !this.$KisokPlayer.video.src) {
          this.$store.commit('setPlay', true);
          this.playerMusic(this.$store.state.channelUrl);
        }
      }
    });
  },

  methods: {
    async getMySpaceInfo () {
      const spaceId = this.$cookies.get('spaceId');
      if (spaceId) {
        try {
          const { data } = await mySpaceInfo(spaceId);
          const { resultCd, result } = data;
          if (resultCd === '0000') {
            this.spaceInfo = result;
          } else {
            this.spaceInfo = null;
          }
        } catch (error) {
          this.spaceInfo = null;
          console.error('Error : ', error);
        }
      }
    },
    linkPlayer () {
      this.$router.push({ name: 'PlayerView' }).catch(() => {});
    },

    getChannelMusicInfo () {
      const channelId = this.$store.state.channelId;
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/player/getChannelMusicInfo', { channelId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (
              this.$store.state.channelMusicInfo == null ||
              result.musicNm !== this.$store.state.channelMusicInfo.musicNm
            ) {
              if (
                this.$store.state.channelMusicInfo != null &&
                result.musicId === this.$store.state.channelMusicInfo.nextMusicId &&
                this.$store.state.channelMusicInfo.nextAlbumImg.indexOf('empty') > -1
              ) {
                result.albumImg = this.$store.state.channelMusicInfo.nextAlbumImg;
              }

              if (
                this.$store.state.channelMusicInfo != null &&
                result.prevMusicId === this.$store.state.channelMusicInfo.musicId &&
                this.$store.state.channelMusicInfo.albumImg.indexOf('empty') > -1
              ) {
                result.prevAlbumImg = this.$store.state.channelMusicInfo.albumImg;
              }

              this.$store.commit('setChannelMusicInfo', result);
              this.setTitle();

              if (this.$store.state.isPlay && !this.$store.state.isBrandMusic) {
                this.checkDupleUse();
              }

              // 곡이 변경될 때마다 기록
              try {
                if (document.location.hostname === 'www.aplayz.co.kr') {
                  this.$gtag.event('music_playing', {
                    space_id: this.$store.state.spaceId,
                    space_nm: this.$store.state.spaceNm,
                    artist: result.artist,
                    music_nm: result.musicNm
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    setTitle () {
      // 브라우저 헤더 영역 음원 정보
      let musicInfo = null;
      // 브랜드 음원인 경우 brandMusicInfo 아니면 channelMusicInfo
      this.$store.state.isBrandMusic
        ? (musicInfo = this.$store.state.brandMusicInfo)
        : (musicInfo = this.$store.state.channelMusicInfo);
      // for mobile media info
      if (this.$MediaSession.init()) {
        this.$MediaSession.setMetaData(musicInfo);
        this.$MediaSession.setActionHandler();
        this.$MediaSession.addPause(this.mediaSessionPause);
        this.$MediaSession.addPlay(this.mediaSessionPlay);
      }
      if (musicInfo) {
        document.title = musicInfo.artist + ' - ' + musicInfo.musicNm;
      }
    },
    videoPlay () {
      if (this.$store.state.isPlay && !this.$store.state.isBrandMusic) {
        this.checkDupleUse();
      }
      try {
        if (document.location.hostname === 'www.aplayz.co.kr') {
          this.$gtag.event('music_playing', {
            space_id: this.$store.state.spaceId,
            space_nm: this.$store.state.spaceNm,
            music_idx: this.$store.state.currentMusicIdx,
            music_nm: this.$store.channelMusicInfo.musicNm
          });
        }
      } catch (e) {
        console.log(e);
      }

      console.log('music play start!');
      navigator.mediaSession.playbackState = 'playing';
    },
    videoEnded () {
      console.log('music play end!');
      this.$store.commit('setIsBrandMusic', false);
      if (this.$store.state.isBrandMusicPlay) {
        this.$store.commit('setIsBrandMusicPlay', false); // 브랜드 음원의 재생 값 false
        this.setTitle();
      }
    },
    videoPause () {
      console.log('music get Pause');
      navigator.mediaSession.playbackState = 'paused';
      // check media ended
      // source buffers 내에 미디어 소스는 종료로 확인 상태에서 pause 상태로 멈춰 다음 곡이 재생 되지 않는 문제 해결
      try {
        if (this.$store.state.isPlay && this.$store.hls !== undefined) {
          // 이용자의 pause 상태 변경 없이 pause 호출 시
          console.log(
            'not triggered',
            this.$store.state.isPlay,
            this.$store.hls.bufferController.sourceBuffer.audio.ended
          );
          if (this.$store.hls.bufferController.sourceBuffer.audio.ended) {
            // 미디어 소스 버퍼의 로딩이 끝까지 완료 된 상태라면.. 1초뒤에도 pause 상태인지 확인 후 처리
            const mIdx = this.$store.state.currentMusicIdx;
            const checkFn = function (idx) {
              console.log('paused trigger', 'before: ', idx, ', now: ', this.$store.state.currentMusicIdx);
              if (idx === this.$store.state.currentMusicIdx) {
                this.$VideoPlayer.videoDisPatchEvent('ended');
              }
            };

            setTimeout(() => {
              checkFn(mIdx);
            }, 1000);
          } else if (this.$store.hls.streamController.fragCurrent.sn < this.$store.hls.bufferController.details.endSN) {
            // Stream Controller에서 다음 Fragment를 로딩하지 않고 멈춰 있는 경우가 있다.
            // 현재 Fragment 일련번호와 전체 일련번호가 같지 않다면 복구를 시도한다.
            console.log('Try to load next chunk file and replay.');
            this.$store.hls.startLoad();
          }
        }
      } catch (e) {
        console.log('Error', e);
      }
    },
    addListener () {
      window.addEventListener('beforeunload', this.beforeunload);
    },

    beforeunload () {
      localStorage.setItem('playMusicInfo', 'Y');
    },

    mediaSessionPlay () {
      this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
      this.$VideoPlayer.startLoad(-1);
      this.$VideoPlayer.onPlay();
    },
    mediaSessionPause () {
      this.$VideoPlayer.onPause();
    },

    checkDupleUse () {
      const userId = VueCookies.get('userId');
      const spaceId = VueCookies.get('spaceId');
      const sessionId = localStorage.getItem('sessionId');
      const recentSpaceId = localStorage.getItem('recentSpaceId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const limitList = ['user_004699', 'user_000236'];

      const findIdx = limitList.findIndex(item => item === userId);
      if (findIdx < 0) {
        axios
          .post('/api/spaceSession/checkDupleUse', { userId, spaceId, sessionId, recentSpaceId }, { headers })
          .then(res => {
            const { resultCd, result } = res.data;

            if (resultCd === '0000') {
              const { resultCd: spaceResultCd, sessionId } = result;
              localStorage.setItem('sessionId', sessionId);
              if (spaceResultCd === 'D0002') {
                this.$VideoPlayer.onPause();
                this.$store.commit('setSpaceConnection', true);
              } else if (spaceResultCd === 'D0003') {
                localStorage.setItem('recentSpaceId', spaceId);
              }
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    channelPreBtn () {
      // 이전 채널 이동
      console.log('channelPreBtn');
      const index = this.recomList.findIndex(el => el.channelId === this.channelInfo.channelId);
      index === -1 || index === 0
        ? this.changeChannel(this.recomList[this.recomList.length - 1].channelId)
        : this.changeChannel(this.recomList[index - 1].channelId);
      // send click event to google analytics
      try {
        if (document.location.hostname === 'www.aplayz.co.kr') {
          this.$gtag.event('click_pre_channel', {
            space_id: this.$store.state.spaceId,
            space_nm: this.$store.state.spaceNm,
            channel_id: this.$store.state.channelId
          });
        }
      } catch (e) {
        /**/
      }
    },
    channelNextBtn () {
      // 다음 채널 이동
      console.log('channelNextBtn');
      const index = this.recomList.findIndex(el => el.channelId === this.channelInfo.channelId);
      index === -1 || index === this.recomList.length - 1
        ? this.changeChannel(this.recomList[0].channelId)
        : this.changeChannel(this.recomList[index + 1].channelId);
      // send click event to google analytics
      try {
        if (document.location.hostname === 'www.aplayz.co.kr') {
          this.$gtag.event('click_next_channel', {
            space_id: this.$store.state.spaceId,
            space_nm: this.$store.state.spaceNm,
            channel_id: this.$store.state.channelId
          });
        }
      } catch (e) {
        /**/
      }
    },
    async changeChannel (dstChannelId) {
      if (dstChannelId !== this.channelInfo.channelId) {
        const result = await joinRecomChannel(dstChannelId);
        if (!result) {
          alert('사용할 수 없는 채널입니다.');
          return false;
        }
        this.setTitle();
        this.$emit('getmychannelinfo');
      }
    }
  },
  updated () {
    this.$MediaSession.addPause(this.mediaSessionPause);
    this.$MediaSession.addPlay(this.mediaSessionPlay);
    this.$MediaSession.addPrevioustrack(this.channelPreBtn);
    this.$MediaSession.addNexttrack(this.channelNextBtn);
  },
  destroyed () {
    this.$VideoPlayer.removeVideoEvent('play', this.videoPlay);
    this.$VideoPlayer.removeVideoEvent('pause', this.videoPause);
    this.$VideoPlayer.removeVideoEvent('ended', this.videEnded);

    window.removeEventListener('beforeunload', this.beforeunload);
    clearInterval(this.musicInfoInterval);
  }
};
</script>
<style scoped>
.floating_inactive {
  visibility: hidden;
}

@media all and (max-width: 500px) {
  .floating_img {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .floating_img2 {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>
